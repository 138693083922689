.Submenu_Style {
    background-color: white !important;
    height: 55px !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
    margin-top: 55px !important;
}
.Submenu_Menu_Justify_Content {
    display: flex !important;
    justify-content: center !important;
}
.Submenu_List_Style {
    display:flex !important;
    list-style:none !important;
}
.Submenu_Link_Style {
    font-size: 15.5px;
    font-weight: 600 !important;
    color: #0b0b0b !important;
    line-height: 14.6px !important;
    letter-spacing: -2% !important;
    padding-top: 19px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.Submenu_Link_Bullet::before {
    content: "•";
    color: #ff541f;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}
.Submenu_Link_Bullet_Hover:hover::before {
    content: "•";
    color: #ff541f;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}

/* Views */

/*
##Device = Low resolution
##Screen = B/w 320px to 1279px
*/

@media (min-width: 1px) and (max-width: 1279px) {
    div.Submenu_View {
        display: none !important;
    }
}
