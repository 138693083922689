.LoginAuthenticate {
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    height: 100vh !important;
    z-index: 1000;
  }

  .corporative-image {
    mix-blend-mode: multiply;
  }