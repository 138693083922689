/* Desktop version */

.Footer_Desktop {
  padding-left: 80px !important;
  padding-right: 80px !important;
  padding-top: 72px !important;
  padding-bottom: 16px !important;
  color: #c3c2c2 !important;
  background-color: #2A2A2A !important;
  border: none !important;
}
.Footer_Titles_Desktop {
  font-size: 15.5px;
  font-weight: 600 !important;
  color: #c3c2c2 !important;
  line-height: 23px !important;
}
.Footer_Links_Desktop {
  color: #c3c2c2;
  text-decoration: none;
}
.Footer_Links_Desktop:hover {
  color: #c3c2c2;
  text-decoration: underline !important;
}
.Footer_Information_Desktop {
  font-size: 25px;
  font-weight: 300 !important;
  line-height: 36px !important;
  color: #c3c2c2 !important;
}
.Footer_Second_Title_Padding_Desktop {
  padding-top: 50px !important;
}
.Footer_Copyright_Padding_Desktop {
  padding-top:40px !important;
}
.Footer_Copyright_Desktop {
  font-size: 13.5px;
  font-weight: 400 !important;
  line-height: 69px!important;
  color: #c3c2c2 !important;
}

/* Mobile version */

.Footer_Mobile {
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-top: 58px !important;
  padding-bottom: 48px !important;
  color: #c3c2c2 !important;
  background-color: #2A2A2A !important;
  border: none !important;
}
.Footer_Titles_Mobile {
  font-size: 15.5px;
  font-weight: 600 !important;
  color: #c3c2c2 !important;
  line-height: 23px !important;
}
.Footer_Information_Mobile {
  font-size: 23px !important;
  font-weight: 300 !important;
  line-height: 30px !important;
  color: #c3c2c2 !important;
}
.FooterLinks_Mobile {
  color: #c3c2c2;
  text-decoration: none;
}
.FooterLinks:hover {
  color: #c3c2c2;
  text-decoration: underline !important;
}
.Footer_Second_Title_Padding_Mobile {
  padding-top: 33px !important;
}
.Footer_Information_Email_Padding_Mobile {
  /*padding-top: 10px !important;*/
}
.Footer_Copyright_Padding_Mobile {
  padding-top: 85px !important;
}
.Footer_Copyright_Mobile {
  font-size: 12.5px;
  font-weight: 400 !important;
  line-height: 69% !important;
  color: #c3c2c2 !important;
} 
.Footer_Information_Address_Padding_Mobile {
  padding-top: 33px !important;
}

/* Views Footer */

/* Views */

/*
    Screen = 500px to higher resolution desktops
*/

@media (min-width: 1025px) {
  div.Footer_Mobile_View {
      display: none !important;
  }
}

/*
*   Screen = 320px to 479px
*/

@media (min-width: 300px) and (max-width: 1024px) {
  div.Footer_Desktop_View {
      display: none !important;
  }
}

/*
*   Screen = 320px to 479px
*/

@media (max-width: 299px) {
  div.Footer_Desktop_View {
      display: none !important;
  }
  div.Footer_Mobile_View {
    display: none !important;
}
}