/* Mobile Menu */

.Mobile_Menu_Style {
    background-color: #FF541F !important;
    padding-left: 42px !important;
    padding-right: 25px !important;
    margin-top: 68px!important;
    position: fixed !important;
    top: 0% !important;
}
.Mobile_Menu_TopOfTheWindow_Style {
    background-color: white !important;
    padding-left: 42px !important;
    padding-right: 25px !important;
    margin-top: 110px!important;
    position: fixed !important;
    top: 0% !important;
}
.Mobile_Menu_List_Style {
    list-style: none !important;
    text-align: left !important;
}
.Mobile_Menu_Link_Padding {
    padding-top: 21px !important;
}
.Mobile_Menu_Link {
    font-size: 26px !important;
    font-weight: 600 !important;
    color: #0b0b0b !important;
    line-height: 20px !important;
    letter-spacing: -1% !important;
}
.Mobile_Menu_Language_Style {
    padding-top: 74px !important;
    padding-bottom: 45px !important;
}
.Mobile_Menu_Sub_Link {
    font-size: 26px !important;
    font-weight: 400 !important;
    color: #0b0b0b !important;
    line-height: 20px !important;
    letter-spacing: -1% !important;
}
.Mobile_Menu_Language_Text {
    font-size: 23px !important;
    font-weight: 500 !important;
    line-height: 20px;
    letter-spacing: -2% !important;
    color: #0b0b0b !important;
}
.Mobile_Menu_Selected_Language_Style {
    text-decoration: underline !important;
}

.Mobile_Selected_Menu_Bullet::before {
    content: "•";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em;
}
.Mobile_Selected_Menu_Bullet_Hover:hover::before {
    content: "•";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: 0em
}

/* Views */

/*
  ##Device = Desktops
  ##Screen = 1440px to higher resolution desktops
*/

@media (min-width: 1279px) {
    div.Mobile_Menu_View {
        display: none !important;
    }
}