/* Desktop version */

.Navbar_Style {
    background-color: #ff541f !important;
    height: 55px !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
}
.Navbar_IdentityCorporative_Link {
    text-decoration: none !important;
}
.Navbar_CorporativeIdentity_Text {
    font-size: 17px !important;;
    font-weight: 800 !important;
    text-decoration: none !important;
    line-height: 20px !important;;
    color: #0b0b0b !important;;

}
.Navbar_CorporativeIdentity_Paddding {
    padding-top: 15px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    white-space: nowrap !important;
}
.Navbar_List_Style {
    display:flex !important;;
    list-style:none !important;;
    padding-left: 0px !important;
}
.Navbar_Menu_JustifyContent {
    display: flex !important;
    justify-content: center !important;
}
.Navbar_LinkText {
    font-size: 17px;
    font-weight: 600 !important;
    color: #0b0b0b !important;
    line-height: 20px !important;
    letter-spacing: -2% !important;
    padding-top: 15px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.Navbar_LanguageText {
    padding-top: 15px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: -2% !important;
    color: #0b0b0b !important;
}
.Navbar_Language_Selected {
    text-decoration: underline !important;
}
.Navbar_LinkText_Bullet::before {
    content: "•";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}
.Navbar_LinkText_Bullet_Hover:hover::before {
    content: "•";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}

/* Mobile version */

.Navbar_Mobile_Style {
    background-color: #ff541f !important;
    height: 68px;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.Navbar_Mobile_CorporativeIdentity_Text {
    font-size: 17px;
    font-weight: 800 !important;
    text-decoration: none !important;
    line-height: 20px;
    color: #0b0b0b;
}
.Navbar_Mobile_Identity_Padding {
    padding-top: 24px !important;
}

.Navbar_Mobile_Icon {
    font-size: 30px !important;
    color: #0b0b0b;
}

.Navbar_Mobile_Button_Circle_Style {
    display: block !important;
    width: 48px !important;
    height: 48px !important;
    border-radius: 50% !important;
    border: 2px solid #0b0b0b;
    background-color: #ff541f !important;
    outline: none !important;
}
.Navbar_Mobile_Button_Without_Circle_Style {
    display: block;
    width: 48px;
    height: 48px;
    border: none !important;
    background-color: #ff541f !important;
    outline: none !important;
}
.Navbar_Mobile_Menu_Padding_Icon {
    padding-top: 10px !important;
}

/* Views */

/*
  ##Device = Desktops
  ##Screen = 1280px to higher resolution desktops
*/

@media (min-width: 1280px) {
    div.Navbar_Mobile_View {
        display: none !important;
    }
}

/*
##Device = Low resolution
##Screen = B/w 320px to 1279px
*/

@media (min-width: 320px) and (max-width: 1279px) {
    div.Navbar_Desktop_View {
        display: none !important;
    }
}

