@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");

body {
  margin: 0;
  padding: 0;
  font-family:'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  color: black;
  line-height: 24px;
  min-width: 300px !important;
  width: 100% !important;
}

img, video {
  pointer-events: none;
}