@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css);
.App_Main_Container {
    width: 100% !important;
}
/* Mobile Menu */

.Mobile_Menu_Style {
    background-color: #FF541F !important;
    padding-left: 42px !important;
    padding-right: 25px !important;
    margin-top: 68px!important;
    position: fixed !important;
    top: 0% !important;
}
.Mobile_Menu_TopOfTheWindow_Style {
    background-color: white !important;
    padding-left: 42px !important;
    padding-right: 25px !important;
    margin-top: 110px!important;
    position: fixed !important;
    top: 0% !important;
}
.Mobile_Menu_List_Style {
    list-style: none !important;
    text-align: left !important;
}
.Mobile_Menu_Link_Padding {
    padding-top: 21px !important;
}
.Mobile_Menu_Link {
    font-size: 26px !important;
    font-weight: 600 !important;
    color: #0b0b0b !important;
    line-height: 20px !important;
    letter-spacing: -1% !important;
}
.Mobile_Menu_Language_Style {
    padding-top: 74px !important;
    padding-bottom: 45px !important;
}
.Mobile_Menu_Sub_Link {
    font-size: 26px !important;
    font-weight: 400 !important;
    color: #0b0b0b !important;
    line-height: 20px !important;
    letter-spacing: -1% !important;
}
.Mobile_Menu_Language_Text {
    font-size: 23px !important;
    font-weight: 500 !important;
    line-height: 20px;
    letter-spacing: -2% !important;
    color: #0b0b0b !important;
}
.Mobile_Menu_Selected_Language_Style {
    text-decoration: underline !important;
}

.Mobile_Selected_Menu_Bullet::before {
    content: "\2022";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em;
}
.Mobile_Selected_Menu_Bullet_Hover:hover::before {
    content: "\2022";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: 0em
}

/* Views */

/*
  ##Device = Desktops
  ##Screen = 1440px to higher resolution desktops
*/

@media (min-width: 1279px) {
    div.Mobile_Menu_View {
        display: none !important;
    }
}
/* Desktop version */

.Navbar_TopOfTheWindow_Style {
    background-color: white !important;
    height: 110px !important;
    padding-left: 80px !important; 
    padding-right: 80px !important;
    border-top: 7px solid #ff541f;
}
.Navbar_TopOfTheWindow_Identity_Padding {
    padding-top: 13px!important;
}
.Navbar_TopOfTheWindow_Menu_JustifyContent {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
}
.Navbar_TopOfTheWindow_List_Style {
    display:-webkit-flex;
    display:flex;
    list-style:none;
    padding: 0px !important;
}
.Navbar_TopOfTheWindow_UL_TextAlign {
    float: right !important;
}
.Navbar_TopOfTheWindow_Link_Style {
    font-size: 17px;
    font-weight: 600 !important;
    line-height: 20px;
    letter-spacing: -2% !important;
    color: #0b0b0b !important;
    padding-top: 15px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.Navbar_TopOfTheWindow_LanguageText {
    padding-top: 15px !important;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 20px;
    letter-spacing: -2% !important;
    color: #0b0b0b !important;
}
.Navbar_TopOfTheWindow_Language_Selected {
    text-decoration: underline !important;
}
.Navbar_TopOfTheWindow_Link_Bullet::before {
    content: "\2022";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}

.Navbar_TopOfTheWindow_Link_Bullet_Hover:hover::before {
    content: "\2022";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}

/* Mobile version */

.Navbar_TopOfTheWindow_Mobile_Style {
    background-color: white !important;
    height: 110px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.Navbar_TopOfTheWindow_Mobile_Identity_Padding {
    padding-top: 20px !important;
}
.Navbar_TopOfTheWindow_Mobile_Menu_Padding {
    padding-top: 20px !important;
}
.Navbar_TopOfTheWindow_Icon {
    font-size: 40px !important;
    color: #ff6f26;
}
.Navbar_TopOfTheView_Display_None {
    display: none;
}
.Navbar_TopOfTheWindow_Mobile_Button_Circle_Style {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50% !important;
    border: 2px solid #ff541f;
    background-color: white !important;
    outline: none !important;
}
.Navbar_TopOfTheWindow_Mobile_Button_Without_Circle_Style {
    display: block;
    width: 70px;
    height: 70px;
    border: none !important;
    background-color: white !important;
    outline: none !important;
}

/* Views */

/*
  ## Device = Desktop
  ## Screen = 1280px to higher resolution desktops
*/

@media (min-width: 1280px) {
    div.Navbar_TopOfTheWindow_Tablet_View {
        display: none !important;
    }
    div.Navbar_TopOfTheWindow_Mobile_View {
        display: none !important;
    }
}

/*
##Device = Tablet
##Screen = B/w 900px to 1279px
*/

@media (min-width: 900px) and (max-width: 1279px) {
    div.Navbar_TopOfTheWindow_Desktop_View {
        display: none !important;
    }
    div.Navbar_TopOfTheWindow_Mobile_View {
        display: none !important;
    }
}

/*
##Device = Mobile
##Screen = B/w 320px to 1279px
*/

@media (min-width: 320px) and (max-width: 899px) {
    div.Navbar_TopOfTheWindow_Desktop_View {
        display: none !important;
    }
    div.Navbar_TopOfTheWindow_Tablet_View {
        display: none !important;
    }
}
.Submenu_Style {
    background-color: white !important;
    height: 55px !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
    margin-top: 55px !important;
}
.Submenu_Menu_Justify_Content {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
}
.Submenu_List_Style {
    display:-webkit-flex !important;
    display:flex !important;
    list-style:none !important;
}
.Submenu_Link_Style {
    font-size: 15.5px;
    font-weight: 600 !important;
    color: #0b0b0b !important;
    line-height: 14.6px !important;
    letter-spacing: -2% !important;
    padding-top: 19px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.Submenu_Link_Bullet::before {
    content: "\2022";
    color: #ff541f;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}
.Submenu_Link_Bullet_Hover:hover::before {
    content: "\2022";
    color: #ff541f;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}

/* Views */

/*
##Device = Low resolution
##Screen = B/w 320px to 1279px
*/

@media (min-width: 1px) and (max-width: 1279px) {
    div.Submenu_View {
        display: none !important;
    }
}

/* Desktop version */

.Navbar_Style {
    background-color: #ff541f !important;
    height: 55px !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
}
.Navbar_IdentityCorporative_Link {
    text-decoration: none !important;
}
.Navbar_CorporativeIdentity_Text {
    font-size: 17px !important;;
    font-weight: 800 !important;
    text-decoration: none !important;
    line-height: 20px !important;;
    color: #0b0b0b !important;;

}
.Navbar_CorporativeIdentity_Paddding {
    padding-top: 15px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    white-space: nowrap !important;
}
.Navbar_List_Style {
    display:-webkit-flex !important;
    display:flex !important;;
    list-style:none !important;;
    padding-left: 0px !important;
}
.Navbar_Menu_JustifyContent {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
}
.Navbar_LinkText {
    font-size: 17px;
    font-weight: 600 !important;
    color: #0b0b0b !important;
    line-height: 20px !important;
    letter-spacing: -2% !important;
    padding-top: 15px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.Navbar_LanguageText {
    padding-top: 15px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: -2% !important;
    color: #0b0b0b !important;
}
.Navbar_Language_Selected {
    text-decoration: underline !important;
}
.Navbar_LinkText_Bullet::before {
    content: "\2022";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}
.Navbar_LinkText_Bullet_Hover:hover::before {
    content: "\2022";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}

/* Mobile version */

.Navbar_Mobile_Style {
    background-color: #ff541f !important;
    height: 68px;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.Navbar_Mobile_CorporativeIdentity_Text {
    font-size: 17px;
    font-weight: 800 !important;
    text-decoration: none !important;
    line-height: 20px;
    color: #0b0b0b;
}
.Navbar_Mobile_Identity_Padding {
    padding-top: 24px !important;
}

.Navbar_Mobile_Icon {
    font-size: 30px !important;
    color: #0b0b0b;
}

.Navbar_Mobile_Button_Circle_Style {
    display: block !important;
    width: 48px !important;
    height: 48px !important;
    border-radius: 50% !important;
    border: 2px solid #0b0b0b;
    background-color: #ff541f !important;
    outline: none !important;
}
.Navbar_Mobile_Button_Without_Circle_Style {
    display: block;
    width: 48px;
    height: 48px;
    border: none !important;
    background-color: #ff541f !important;
    outline: none !important;
}
.Navbar_Mobile_Menu_Padding_Icon {
    padding-top: 10px !important;
}

/* Views */

/*
  ##Device = Desktops
  ##Screen = 1280px to higher resolution desktops
*/

@media (min-width: 1280px) {
    div.Navbar_Mobile_View {
        display: none !important;
    }
}

/*
##Device = Low resolution
##Screen = B/w 320px to 1279px
*/

@media (min-width: 320px) and (max-width: 1279px) {
    div.Navbar_Desktop_View {
        display: none !important;
    }
}


.NavigationBarAdminStyle {
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.active {
    font-weight: bold;
    /* Otros estilos de resaltado si lo deseas */
  }
/* Desktop version */

.Footer_Desktop {
  padding-left: 80px !important;
  padding-right: 80px !important;
  padding-top: 72px !important;
  padding-bottom: 16px !important;
  color: #c3c2c2 !important;
  background-color: #2A2A2A !important;
  border: none !important;
}
.Footer_Titles_Desktop {
  font-size: 15.5px;
  font-weight: 600 !important;
  color: #c3c2c2 !important;
  line-height: 23px !important;
}
.Footer_Links_Desktop {
  color: #c3c2c2;
  text-decoration: none;
}
.Footer_Links_Desktop:hover {
  color: #c3c2c2;
  text-decoration: underline !important;
}
.Footer_Information_Desktop {
  font-size: 25px;
  font-weight: 300 !important;
  line-height: 36px !important;
  color: #c3c2c2 !important;
}
.Footer_Second_Title_Padding_Desktop {
  padding-top: 50px !important;
}
.Footer_Copyright_Padding_Desktop {
  padding-top:40px !important;
}
.Footer_Copyright_Desktop {
  font-size: 13.5px;
  font-weight: 400 !important;
  line-height: 69px!important;
  color: #c3c2c2 !important;
}

/* Mobile version */

.Footer_Mobile {
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-top: 58px !important;
  padding-bottom: 48px !important;
  color: #c3c2c2 !important;
  background-color: #2A2A2A !important;
  border: none !important;
}
.Footer_Titles_Mobile {
  font-size: 15.5px;
  font-weight: 600 !important;
  color: #c3c2c2 !important;
  line-height: 23px !important;
}
.Footer_Information_Mobile {
  font-size: 23px !important;
  font-weight: 300 !important;
  line-height: 30px !important;
  color: #c3c2c2 !important;
}
.FooterLinks_Mobile {
  color: #c3c2c2;
  text-decoration: none;
}
.FooterLinks:hover {
  color: #c3c2c2;
  text-decoration: underline !important;
}
.Footer_Second_Title_Padding_Mobile {
  padding-top: 33px !important;
}
.Footer_Information_Email_Padding_Mobile {
  /*padding-top: 10px !important;*/
}
.Footer_Copyright_Padding_Mobile {
  padding-top: 85px !important;
}
.Footer_Copyright_Mobile {
  font-size: 12.5px;
  font-weight: 400 !important;
  line-height: 69% !important;
  color: #c3c2c2 !important;
} 
.Footer_Information_Address_Padding_Mobile {
  padding-top: 33px !important;
}

/* Views Footer */

/* Views */

/*
    Screen = 500px to higher resolution desktops
*/

@media (min-width: 1025px) {
  div.Footer_Mobile_View {
      display: none !important;
  }
}

/*
*   Screen = 320px to 479px
*/

@media (min-width: 300px) and (max-width: 1024px) {
  div.Footer_Desktop_View {
      display: none !important;
  }
}

/*
*   Screen = 320px to 479px
*/

@media (max-width: 299px) {
  div.Footer_Desktop_View {
      display: none !important;
  }
  div.Footer_Mobile_View {
    display: none !important;
}
}
.LoginAuthenticate {
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    height: 100vh !important;
    z-index: 1000;
  }

  .corporative-image {
    mix-blend-mode: multiply;
  }
body {
  margin: 0;
  padding: 0;
  font-family:'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  color: black;
  line-height: 24px;
  min-width: 300px !important;
  width: 100% !important;
}

img, video {
  pointer-events: none;
}
