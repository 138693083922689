/* Desktop version */

.Navbar_TopOfTheWindow_Style {
    background-color: white !important;
    height: 110px !important;
    padding-left: 80px !important; 
    padding-right: 80px !important;
    border-top: 7px solid #ff541f;
}
.Navbar_TopOfTheWindow_Identity_Padding {
    padding-top: 13px!important;
}
.Navbar_TopOfTheWindow_Menu_JustifyContent {
    display: flex !important;
    justify-content: center !important;
}
.Navbar_TopOfTheWindow_List_Style {
    display:flex;
    list-style:none;
    padding: 0px !important;
}
.Navbar_TopOfTheWindow_UL_TextAlign {
    float: right !important;
}
.Navbar_TopOfTheWindow_Link_Style {
    font-size: 17px;
    font-weight: 600 !important;
    line-height: 20px;
    letter-spacing: -2% !important;
    color: #0b0b0b !important;
    padding-top: 15px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.Navbar_TopOfTheWindow_LanguageText {
    padding-top: 15px !important;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 20px;
    letter-spacing: -2% !important;
    color: #0b0b0b !important;
}
.Navbar_TopOfTheWindow_Language_Selected {
    text-decoration: underline !important;
}
.Navbar_TopOfTheWindow_Link_Bullet::before {
    content: "•";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}

.Navbar_TopOfTheWindow_Link_Bullet_Hover:hover::before {
    content: "•";
    color: #0b0b0b;
    display: inline-block;
    top: -1.5px !important;
    position: relative;
    width: 1em;
    margin-left: -1em
}

/* Mobile version */

.Navbar_TopOfTheWindow_Mobile_Style {
    background-color: white !important;
    height: 110px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.Navbar_TopOfTheWindow_Mobile_Identity_Padding {
    padding-top: 20px !important;
}
.Navbar_TopOfTheWindow_Mobile_Menu_Padding {
    padding-top: 20px !important;
}
.Navbar_TopOfTheWindow_Icon {
    font-size: 40px !important;
    color: #ff6f26;
}
.Navbar_TopOfTheView_Display_None {
    display: none;
}
.Navbar_TopOfTheWindow_Mobile_Button_Circle_Style {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50% !important;
    border: 2px solid #ff541f;
    background-color: white !important;
    outline: none !important;
}
.Navbar_TopOfTheWindow_Mobile_Button_Without_Circle_Style {
    display: block;
    width: 70px;
    height: 70px;
    border: none !important;
    background-color: white !important;
    outline: none !important;
}

/* Views */

/*
  ## Device = Desktop
  ## Screen = 1280px to higher resolution desktops
*/

@media (min-width: 1280px) {
    div.Navbar_TopOfTheWindow_Tablet_View {
        display: none !important;
    }
    div.Navbar_TopOfTheWindow_Mobile_View {
        display: none !important;
    }
}

/*
##Device = Tablet
##Screen = B/w 900px to 1279px
*/

@media (min-width: 900px) and (max-width: 1279px) {
    div.Navbar_TopOfTheWindow_Desktop_View {
        display: none !important;
    }
    div.Navbar_TopOfTheWindow_Mobile_View {
        display: none !important;
    }
}

/*
##Device = Mobile
##Screen = B/w 320px to 1279px
*/

@media (min-width: 320px) and (max-width: 899px) {
    div.Navbar_TopOfTheWindow_Desktop_View {
        display: none !important;
    }
    div.Navbar_TopOfTheWindow_Tablet_View {
        display: none !important;
    }
}